import colors from "ansi-colors";

export default {
	routerConfigurationLoaded: {
		level: "debug",
		message: ({ routeConfigs }) => `🚦 Vue router configuration loaded for the following routes:\n${routeConfigs}`,
	},
	routeChanged: {
		level: "info",
		message: ({ routeFrom, routeTo, meta, fromParams, toParams, diff }, { routeFrom: rawRouteFrom, diff: rawDiff }) =>
			`🚦 Route changed from\n` +
			(rawRouteFrom.name ? `${routeFrom} - ${fromParams}` : `${colors.blue("<INITIAL PAGE LOAD>")}`) +
			`\nto\n${routeTo}- ${toParams}` +
			(rawDiff.length > 0 ? (rawRouteFrom.name ? "\nChanges to query string:" : "\nQuery string:") + `\n${diff}` : "") +
			(meta ? `\nMeta:${meta}` : ""),
	},
	errorDuringRouteNavigation: {
		level: "warn",
		message: ({ error, to, from }) => `Error during route navigation from ${from} to ${to}:\n${error}`,
	},
	errorDynamicallyImportingRoute: {
		level: "warn",
		message: ({ path }) => `Error dynamically importing route ${path}`,
	},
	errorDynamicallyImportingRouteWithCacheBusting: {
		level: "warn",
		message: ({ path }) => `Error dynamically importing route, with cache busting ${path}`,
	},
	routerError: {
		level: "error",
		message: ({ error, to, from }) => `Router error navigating from ${from} to ${to}:\n${error}`,
	},
};
