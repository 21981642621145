import { computed, inject } from "vue";

export const useUser = () => {
	const currentEnvironment = inject("currentEnvironment");

	const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);
	const loggedInUser = computed(() => currentEnvironment.value.loggedInUser.value);
	const activeUser = computed(() => currentEnvironment.value.activeUser.value);
	const userId = computed(() => activeUser.value?.id);

	return {
		isSignedIn,
		loggedInUser,
		activeUser,
		userId,
	};
};
