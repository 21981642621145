import { reactive } from "vue";
import { useRouter } from "vue-router";

import { computedLazy } from "./computedLazy.js";

/* WHY: We use computedLazy here because it will not trigger a reactive effect if the value is the same as before. This is important as we don't want to trigger a reactive effect when the route param changes but the value is the same (which would cause all active models to reload) */
export const useRouteParams = () => {
	const router = useRouter();
	return reactive({
		cityName: computedLazy(() => router.currentRoute.value.params.city?.toLowerCase()),
		categoryName: computedLazy(() => router.currentRoute.value.params.categoryName),
		areaId: computedLazy(() => router.currentRoute.value.params.areaId),
		zoneId: computedLazy(() => router.currentRoute.value.params.zoneId),
		tagId: computedLazy(() => router.currentRoute.value.params.tagId),
		listId: computedLazy(() => router.currentRoute.value.params.listId),
		venueId: computedLazy(() => router.currentRoute.value.params.venueId),
		venueProductId: computedLazy(() => router.currentRoute.value.params.venueProductId),
		userId: computedLazy(() => router.currentRoute.value.params.userId),
	});
};
