export const PageNames = {
	LOGGED_IN: "LoggedIn",
	DOWNLOAD: "Download",
	CITY: "City",
	LOG_IN: "City/Login",
	CREATE_PROFILE: "City/CreateProfile",
	OTHER_USER_PROFILE: "City/User/Profile",
	CURRENT_USER_PROFILE: "City/Profile",
	HOME_FEED: "City/Home",
	MAP: "City/Map",
	EXPLORE_AREAS: "City/Explore/Areas",
	EXPLORE_AREA: "City/Explore/Areas/Area",
	EXPLORE_LISTS: "City/Explore/Lists",
	EXPLORE_LISTS_CATEGORY: "City/Explore/Lists/Category",
	EXPLORE_LIST: "City/Explore/List",
	EXPLORE_ZONE: "City/Explore/Zone",
	EXPLORE_ZONE_TAG: "City/Explore/Zone/Tag",
	EXPLORE_TAGS: "City/Explore/Tags",
	EXPLORE_TAG: "City/Explore/Tag",
	EXPLORE_VENUE: "City/Explore/Areas/Venue",
	ADMIN: "City/Admin",
	ADMIN_INFO: "City/Admin/Info",
	ADMIN_PERFORMANCE: "City/Admin/Performance",
	PERKS: "Perks",
	PRIVACY_POLICY: "PrivacyPolicy",
	PROFILE_FOLLOWERS: "City/Profile/Followers",
	PROFILE_FOLLOWING: "City/Profile/Following",
	PROFILE_USER_LIST_BEEN: "City/Profile/UserList/Been",
	PROFILE_USER_LIST_FAVOURITES: "City/Profile/UserList/Favourites",
	PROFILE_USER_LIST_GO_TRY: "City/Profile/UserList/GoTry",
	OTHER_USER_FOLLOWERS: "City/User/Profile/Followers",
	OTHER_USER_FOLLOWING: "City/User/Profile/Following",
	OTHER_USER_USER_LIST_BEEN: "City/User/Profile/UserList/Been",
	OTHER_USER_USER_LIST_FAVOURITES: "City/User/Profile/UserList/Favourites",
	OTHER_USER_USER_LIST_GO_TRY: "City/User/Profile/UserList/GoTry",
	PROFILE_SETTINGS: "City/Profile/Settings",
	VENUE_PRODUCT_ENQUIRY: "Enquiry/Venue/Product",
};
