<template>
	<component
		:is="component"
		v-bind="componentProps"
		:validation="validation"
		:state="state"
		:style="style"
		:placeholder="placeholder"
		:is-read-only="isReadOnly"
		:data-field-name="field.name"
		:data-type="dataType"
		:class="{ 'is-label-hidden': componentProps.isLabelHidden, 'is-field-centered': componentProps.isFieldCentered }"
		@change="updateState"
	/>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
	entityFieldState: {
		type: Object,
		required: true,
	},
});

const field = computed(() => props.entityFieldState.field);
const validation = computed(() => props.entityFieldState.validation);
const state = computed(() => props.entityFieldState.state);
const updateState = computed(() => props.entityFieldState.updateState);
const component = computed(() => field.value.component);
const componentProps = computed(() => field.value.componentProps ?? {});
const dataType = computed(() => field.value.dataType ?? String);
const style = computed(() => field.value.style ?? {});
const placeholder = computed(() => field.value.placeholder ?? null);
const isReadOnly = computed(() => field.value.isReadOnly ?? false);
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.is-label-hidden {
	grid-column: 1 / span 2;
}
.is-field-centered {
	justify-self: center;
}
</style>
