import { breakpoints } from "../domain/index.js";

const ANIMATIONS = {
	fadeIn: { enterActiveClass: "animate__fadeIn", leaveActiveClass: "animate__fadeOut" },
	slideInFromLeft: { enterActiveClass: "animate__slideInLeft", leaveActiveClass: "animate__slideOutRight" },
	slideInFromRight: { enterActiveClass: "animate__slideInRight", leaveActiveClass: "animate__slideOutLeft" },
};

export const AnimatedRoutes = {
	install: (app, { router } = {}) => {
		router.beforeEach((to, from) => {
			const firstMatchedRouteToAnimated = to.matched
				.map((route, index) => {
					const toRouteChild = to.matched[index + 1];
					const fromRouteChild = from.matched[index + 1];
					const direction = calculateDirection(toRouteChild, fromRouteChild);
					return {
						direction,
						// toRoute: route,
						route: toRouteChild,
						// fromRouteChild,
						index,
					};
				})
				.find(({ route }) => route?.meta?.animate);

			const firstMatchedRouteFromAnimated = from.matched
				.map((route, index) => {
					const toRouteChild = to.matched[index + 1];
					const fromRouteChild = from.matched[index + 1];
					const direction = calculateDirection(toRouteChild, fromRouteChild);
					return {
						direction,
						// fromRoute: route,
						// toRouteChild,
						route: fromRouteChild,
						index,
					};
				})
				.find(({ route }) => route?.meta?.animate);

			const isMobileOnly = firstMatchedRouteToAnimated?.route.meta?.animate?.isMobileOnly ?? firstMatchedRouteFromAnimated?.route.meta?.animate?.isMobileOnly ?? false;
			const isSmallerThanMedium = breakpoints.smaller("medium");
			const enterOverride = !isMobileOnly || isSmallerThanMedium ? firstMatchedRouteToAnimated?.route.meta?.animate?.enter : null;
			const leaveOverride = !isMobileOnly || isSmallerThanMedium ? firstMatchedRouteFromAnimated?.route.meta?.animate?.leave : null;
			// const zIndex = firstMatchedRouteToAnimated?.route.meta?.animate?.zIndex ?? firstMatchedRouteFromAnimated?.route.meta?.animate?.zIndex ?? null;

			if (firstMatchedRouteToAnimated || enterOverride || leaveOverride) {
				const { direction } = firstMatchedRouteToAnimated ?? {};
				const enterActiveClassOverride = enterOverride ? `animate__${enterOverride}` : null;
				const leaveActiveClassOverride = leaveOverride ? `animate__${leaveOverride}` : null;
				const transition = getTransitionAnimationData(direction);
				const enterActiveClass = transition.enterActiveClass && !leaveActiveClassOverride ? transition.enterActiveClass : null;
				const leaveActiveClass = transition.leaveActiveClass && !enterActiveClassOverride ? transition.leaveActiveClass : null;
				to.meta.transition =
					enterActiveClassOverride || enterActiveClass || leaveActiveClassOverride || leaveActiveClass
						? {
								enterActiveClass: enterActiveClassOverride || enterActiveClass ? ["animate__animated", enterActiveClassOverride ?? enterActiveClass].join(" ") : "",
								leaveActiveClass: leaveActiveClassOverride || leaveActiveClass ? ["animate__animated", leaveActiveClassOverride ?? leaveActiveClass].join(" ") : "",
						  }
						: null;
			} else {
				to.meta.transition = null;
			}
		});

		function getTransitionAnimationData(direction) {
			const isSmallerThanMedium = breakpoints.smaller("medium");
			let animationData;
			if (isSmallerThanMedium.value) {
				switch (direction) {
					case "left":
						animationData = ANIMATIONS.slideInFromLeft;
						break;
					case "right":
						animationData = ANIMATIONS.slideInFromRight;
						break;
					default:
						animationData = ANIMATIONS.fadeIn;
						break;
				}
			} else {
				animationData = ANIMATIONS.fadeIn;
			}
			return animationData;
		}

		function calculateDirection(to, from) {
			const allRoutes = router.getRoutes();
			const toParentRoute = allRoutes.find(({ children }) => children.find(({ name }) => name === to?.name));
			const fromParentRoute = allRoutes.find(({ children }) => children.find(({ name }) => name === from?.name));
			const isSameParent = toParentRoute && fromParentRoute ? toParentRoute === fromParentRoute : false;
			if (isSameParent) {
				const toIndex = toParentRoute.children.findIndex(({ name }) => name === to.name);
				const fromIndex = fromParentRoute.children.findIndex(({ name }) => name === from.name);
				const direction = toIndex < fromIndex ? "left" : "right";
				return direction;
			} else {
				// const toDepth = to.matched.length;
				// const fromDepth = from.matched.length;
				// const direction = toDepth < fromDepth ? "left" : "right";
				// return direction;
				return null;
			}
		}
	},
};
