<template>
	<div v-if="shouldShowVenueCount" class="venue-count-summary">
		<div v-for="{ id, name, count } in USER_LIST_TYPES" :key="id" :class="{ 'user-list': true, 'admin-data': isOnlyShowingVenueCountBecauseAdmin }">
			<span :class="{ 'count': true, 'admin-data-highlighted': isOnlyShowingVenueCountBecauseAdmin }">{{ count }}</span>
			<span class="name">{{ name }}</span>
		</div>
	</div>
</template>

<script setup>
import { computed, reactive } from "vue";

import { useAdmin } from "../../composables/index.js";

const MIN_TIMES_ADDED_TO_USER_LISTS_THRESHOLD = 200;

const USER_LIST_TYPES = reactive([
	{
		id: computed(() => `been-${props.venue.id}`),
		name: computed(() => "Been"),
		count: computed(() => props.venue.beenCount),
	},
	{
		id: computed(() => `favourite-${props.venue.id}`),
		name: computed(() => "Favourite"),
		count: computed(() => props.venue.favouriteCount),
	},
	{
		id: computed(() => `goTry-${props.venue.id}`),
		name: computed(() => "Go Try"),
		count: computed(() => props.venue.gotryCount),
	},
]);

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

const { isAdmin } = useAdmin();

const totalTimesAddedToUserLists = computed(() => USER_LIST_TYPES.reduce((acc, { count }) => acc + count, 0));
const isVenueAddedMoreThanThreshold = computed(() => totalTimesAddedToUserLists.value >= MIN_TIMES_ADDED_TO_USER_LISTS_THRESHOLD);
const shouldShowVenueCount = computed(() => isVenueAddedMoreThanThreshold.value || isAdmin.value);
const isOnlyShowingVenueCountBecauseAdmin = computed(() => !isVenueAddedMoreThanThreshold.value && isAdmin.value);
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.venue-count-summary {
	font-size: $text-size-secondary;
	color: $text-color-secondary;
	display: flex;
	flex-wrap: wrap;

	.user-list {
		display: flex;

		.count {
			font-weight: $text-bold-primary;
			color: $text-color-primary;
			margin-right: calc($spacing / 4);
		}

		.name {
			white-space: nowrap;
		}

		&:not(:last-child)::after {
			content: ",";
			margin-right: calc($spacing/4);
			color: $text-color-secondary;
		}
	}
}
</style>
