import { helpers, minLength, required, numeric, minValue } from "@vuelidate/validators";

import { EntityTypeNames } from "../../../constants/index.js";

import FormFieldInput from "../../../components/forms/FormFieldInput.vue";
import FormFieldPhone from "../../../components/forms/FormFieldPhone.vue";
import FormFieldDateTime from "../../../components/forms/FormFieldDateTime.vue";

export default class BookingReservationDetailsInputType {
	static typeName = EntityTypeNames.VenueProductEnquiryInput;

	constructor({ addChange }) {
		this.addChange = addChange;

		this.fields = [
			{
				name: "firstName",
				component: FormFieldInput,
				label: "First Name",
				placeholder: "Enter your first name",
				validation: { required: helpers.withMessage("Please enter a first name", required) },
			},
			{
				name: "lastName",
				component: FormFieldInput,
				label: "Last Name",
				placeholder: "Enter your last name",
				validation: { required: helpers.withMessage("Please enter a last name", required) },
			},
			{
				name: "email",
				component: FormFieldInput,
				label: "Email",
				placeholder: "Enter your email address",
				validation: { required: helpers.withMessage("Please enter an email address", required) },
			},
			{
				name: "phone",
				component: FormFieldPhone,
				label: "Phone Number",
				placeholder: "Enter your phone number",
				validation: {
					number: {
						numeric: helpers.withMessage("Your phone number must contain only numbers", numeric),
						minLength: helpers.withMessage("Your phone number must be valid", minLength(6)),
					},
				},
			},
			{
				name: "preferredDate",
				component: FormFieldDateTime,
				label: "Preferred Date",
				placeholder: "Enter your preferred date",
				validation: {
					minValue: helpers.withMessage("Please enter a preferred date in the future", (val) => new Date(val) > new Date()),
					required: helpers.withMessage("Please enter a preferred date", required),
				},
			},
			{
				name: "partySize",
				component: FormFieldInput,
				dataType: Number,
				label: "Party Size",
				placeholder: "Enter your party size",
				validation: {
					numeric: helpers.withMessage("Please enter a valid number", numeric),
					minValue: helpers.withMessage("Please enter a valid party size (atleast 1)", minValue(1)),
					required: helpers.withMessage("Please enter your party size", required),
				},
			},
			{
				name: "budget",
				component: FormFieldInput,
				componentProps: {
					prefix: "£",
				},
				dataType: Number,
				label: "Budget",
				placeholder: "Enter your budget",
				validation: {
					numeric: helpers.withMessage("Please enter a valid number", numeric),
					required: helpers.withMessage("Please enter your budget", required),
				},
			},
			{
				name: "additionalInformation",
				component: FormFieldInput,
				componentProps: { isMultiLine: true },
				label: "Addtional Information",
				placeholder: "Occasion, Dietary Requirements, etc.",
			},
		];
	}
}
