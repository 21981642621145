import { ErrorNested } from "./ErrorNested.js";

export class ApiRequestQueryError extends ErrorNested {
	constructor(message, error, extensions) {
		super("ApiRequestQueryError", `An error occurred calling the API query: ${message}`, {
			exception: error,
			code: "API_REQUEST_QUERY_ERROR",
			...extensions,
		});
	}
}
