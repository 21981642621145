<template>
	<Dialog ref="dialogRef" param-name="browserOpen" class="browser-dialog" width="90vw" height="min(80dvh, 850px)" max-width="min(95vw, 420px)" :is-fake-modal="true" @closed="closed">
		<DialogSimpleLayout :full-width="true" :show-padding="false" :header="queryString.browserTitle" @close="close">
			<EmbeddedBrowser v-if="queryString.browserUrl" :url="queryString.browserUrl" class="embedded-browser" />
			<div v-else>No URL provided</div>
		</DialogSimpleLayout>
	</Dialog>
	<div ref="measureScrollBarWidthRef" class="measure-scrollbar-width"></div>
</template>

<script setup>
import { ref } from "vue";

import { useQueryString } from "../functions/query-string/index.js";

import Dialog from "./Dialog.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";
import EmbeddedBrowser from "./EmbeddedBrowser.vue";

const DEFAULT_DIALOG_HEADER = "onezone";

const queryString = useQueryString(
	{
		browserUrl: null,
		browserTitle: DEFAULT_DIALOG_HEADER,
	},
	{ persist: true },
);

const dialogRef = ref(null);
const measureScrollBarWidthRef = ref(null);

defineExpose({
	open,
	close,
});

async function open({ url, title } = {}) {
	const dialog = dialogRef.value;
	if (!url) {
		throw new Error("URL is required to open the browser dialog.");
	}
	queryString.browserUrl = url;
	queryString.browserTitle = title ?? DEFAULT_DIALOG_HEADER;

	if (dialog && !dialog.isOpen) {
		return dialog.open();
	}
}

async function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

function closed() {
	queryString.remove("browserUrl");
	queryString.remove("browserTitle");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.browser-dialog {
	overflow: hidden;

	:deep(.logo) {
		width: 100px;
		margin: 0 auto;
	}

	:deep(.embedded-browser) {
		flex-grow: 1;
	}
}
</style>
