<template>
	<Dialog ref="dialogRef" param-name="feedbackOpen" class="feedback-dialog" width="90vw" max-height="min(85dvh, 650px)" max-width="min(95vw, 420px)" @closed="closed">
		<DialogSimpleLayout header="Something Wrong" :full-width="true" :show-padding="false" :has-back-button="!!currentStage?.back" @close="close" @back="currentStage?.back">
			<loading-message-with-error v-if="query.isLoading" :error="query.error" :full-screen="true" />
			<div v-else class="feedback-dialog-content">
				<VenueHeader :venue="venue" />
				<div v-if="currentStageName === STAGE_NAMES.SELECT_OPTION" class="stage stage-select-option">
					<div class="intro">
						<p>Our team are working hard to make sure the information on onezone is correct.</p>
						<p>Feedback from our community is incredibly helpful. Please let us know if something is wrong by selecting one of the options below:</p>
					</div>
					<ul class="feedback-options">
						<li v-for="option in FEEDBACK_OPTIONS" :key="option.name">
							<button class="push-button-primary" @click="selectFeedbackOption(option)">{{ option.name }}</button>
						</li>
					</ul>
				</div>
				<div v-else-if="currentStageName === STAGE_NAMES.COLLECT_DETAILS" class="stage stage-collect-details">
					<div class="selected-option">{{ selectedFeedbackOption?.name }}</div>
					<div class="collect-details-form">
						<p>Any additional information you would like to tell us?</p>
						<textarea v-model="feedbackDetails" maxlength="255"></textarea>
						<button class="push-button-call-to-action" @click="submitFeedbackDetails">Submit</button>
					</div>
				</div>
				<div v-else-if="currentStageName === STAGE_NAMES.COMPLETE" class="stage stage-complete">
					<p>We Appreciate You</p>
				</div>
			</div>
		</DialogSimpleLayout>
	</Dialog>
</template>

<script setup>
import { computed, inject, ref } from "vue";

import { useQueryString } from "../functions/query-string/index.js";

import Dialog from "./Dialog.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";
import VenueHeader from "./venue/VenueHeader.vue";

const FEEDBACK_OPTIONS = [
	{
		id: "booking-link",
		name: "Booking Link",
	},
	{
		id: "menu-link",
		name: "Menu Link",
	},
	{
		id: "opening-hours",
		name: "Opening Hours",
	},
	{
		id: "perk",
		name: "Perk",
	},
	{
		id: "permanently-closed",
		name: "Permanently Closed",
	},
	{
		id: "something-else",
		name: "Something Else",
	},
];
const STAGE_NAMES = {
	SELECT_OPTION: "select-option",
	COLLECT_DETAILS: "collect-details",
	COMPLETE: "complete",
};

const STAGES = [
	{
		name: STAGE_NAMES.COMPLETE,
		isCurrentStage: () => queryString.venueId && queryString.feedbackOptionId && queryString.feedbackDetails,
	},
	{
		name: STAGE_NAMES.COLLECT_DETAILS,
		isCurrentStage: () => queryString.venueId && queryString.feedbackOptionId,
		back: () => {
			queryString.feedbackOptionId = undefined;
			queryString.feedbackDetails = undefined;
		},
	},
	{
		name: STAGE_NAMES.SELECT_OPTION,
		isCurrentStage: () => queryString.venueId,
	},
];

const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
const tracking = inject("tracking");

const queryString = useQueryString(
	{
		venueId: null,
		feedbackOptionId: null,
		feedbackDetails: null,
	},
	{ persist: true },
);

const dialogRef = ref(null),
	feedbackDetails = ref(null);

const isSignedIn = computed(() => currentEnvironment.value?.isSignedIn.value);

const query = model.queries.GetVenueDetail({ venueId: computed(() => queryString.venueId), isSignedIn }, { isWatchEnabled: computed(() => true) });

const venue = computed(() => query.model?.venue);
const currentStage = computed(() => STAGES.find((step) => step?.isCurrentStage()));
const currentStageName = computed(() => currentStage.value?.name);
const selectedFeedbackOption = computed(() => FEEDBACK_OPTIONS.find((option) => option.id === queryString.feedbackOptionId));

defineExpose({
	open,
	close,
});

async function open(args = {}) {
	const dialog = dialogRef.value;
	if (!args.venueId) {
		throw new Error("venueId is required to open the feedback dialog");
	}
	queryString.venueId = args.venueId;

	if (dialog && !dialog.isOpen) {
		return dialog.open();
	}
}

async function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

function closed() {
	queryString.remove("venueId");
	queryString.remove("feedbackOptionId");
	queryString.remove("feedbackDetails");
	feedbackDetails.value = null;
}

function selectFeedbackOption(option) {
	queryString.feedbackOptionId = option.id;
}

function submitFeedbackDetails() {
	queryString.feedbackDetails = feedbackDetails.value ?? "true";
	tracking?.feedbackSubmitted({ venue: venue.value, feedbackOption: selectedFeedbackOption.value, feedbackDetails: feedbackDetails.value });
	feedbackDetails.value = null;
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.feedback-dialog {
	:deep(.feedback-dialog-content) {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: calc($spacing * 1.5);
		overflow: auto;
		margin: calc($spacing) calc($spacing * 2);

		.stage {
			display: flex;
			flex-direction: column;
			gap: calc($spacing * 1.5);
			overflow: auto;

			&.stage-select-option {
				.intro {
					display: flex;
					flex-direction: column;
					gap: calc($spacing);
					font-size: $text-size-secondary;

					> p {
						margin: 0;
						text-align: center;
					}
				}

				.feedback-options {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: calc($spacing / 2);
					list-style: none;
					margin: 0;
					padding: 0 0 calc($spacing * 2) 0;

					> li > button {
						width: 200px;
						font-weight: $text-bold-secondary;
					}
				}
			}

			&.stage-collect-details {
				.selected-option {
					font-size: $text-size-quaternary;
					font-weight: $text-bold-secondary;
					text-align: center;
				}
				.collect-details-form {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: calc($spacing * 1.5);

					> p {
						font-size: $text-size-secondary;
						text-align: center;
						margin: 0;
					}
					> textarea {
						border-color: $border-color-primary;
						border-radius: $border-radius-secondary;
						font-size: $text-size-secondary;
						box-sizing: border-box;
						padding: calc($spacing / 2);
						width: 100%;
						height: 100px;
					}
					> button {
						width: 80px;
					}
				}
			}

			&.stage-complete {
				display: flex;
				flex-direction: column;
				justify-content: center;
				font-size: $text-size-quaternary;
				font-weight: $text-bold-secondary;
				text-align: center;
				min-height: 100px;

				> p {
					margin: 0 0 calc($spacing * 3) 0;
				}
			}
		}
	}
}
</style>
