<template>
	<ScrollableContainer class="venue-product-enquiry">
		<error-message v-if="!canShowEnquiryForm" :full-screen="true">Unable to show enquiry form for this event</error-message>
		<template v-else>
			<VenueHeader :venue="venue" />
			<div class="venue-product-name">{{ venueProduct.name }}</div>
			<div v-if="queryString.enquiryStage" class="enquiry-result">
				<template v-if="queryString.enquiryStage === ENQUIRY_STAGE.SUCCESS">
					<p>Thank you for your enquiry.</p>
					<p>The team at {{ venue.name }} will be in touch soon.</p>
				</template>
				<template v-if="queryString.enquiryStage === ENQUIRY_STAGE.ERROR">
					<p>Oops, something went wrong.</p>
					<p>If the issue continues, feel free to contact us at <a href="mailto:info@onezone.app">info@onezone.app</a>.</p>
				</template>
			</div>
			<EntityForm v-else v-slot="{ bind, on }" :sync-state="true" :entity="enquiry" :fields-config="fieldsConfig" :type-name="EntityTypeNames.VenueProductEnquiryInput" @save="submitEnquiry">
				<FormLayoutSimple v-bind="{ ...bind, serverErrorMessage: bind.error }" :show-labels="true" :is-single-column="true" v-on="on">
					<template #save-button>
						<div class="save-button-container">
							<button class="push-button-call-to-action" @click="on.save">Enquire</button>
						</div>
					</template>
				</FormLayoutSimple>
			</EntityForm>
		</template>
	</ScrollableContainer>
</template>

<script setup>
import { computed, inject, onUnmounted, reactive, watch } from "vue";

import { useQueryString } from "../../functions/query-string/index.js";
import { EntityTypeNames } from "../../constants/index.js";
import { useUser } from "../../composables/index.js";
import loggingMessages from "./VenueProductEnquiry.logging-messages.js";

import EntityForm from "../forms/EntityForm.vue";
import FormLayoutSimple from "../forms/FormLayoutSimple.vue";
import VenueHeader from "./VenueHeader.vue";
import ScrollableContainer from "../ScrollableContainer.vue";

const ENQUIRY_STAGE = {
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
};

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	venueProduct: {
		type: Object,
		required: true,
	},
});

const model = inject("model");
const tracking = inject("tracking");
const logger = inject("logger");

const { activeUser } = useUser();
const queryString = useQueryString({
	enquiryStage: null,
});

const enquiry = reactive({
	firstName: null,
	lastName: null,
	email: null,
	phone: {
		number: null,
		countryCode: "UK",
	},
	partySize: null,
	preferredDate: null,
	budget: null,
	additionalInformation: null,
});
const fieldsConfig = reactive({});

const venue = computed(() => props.venue);
const venueProduct = computed(() => props.venueProduct);
const venueProductId = computed(() => props.venueProduct.id);
const canShowEnquiryForm = computed(() => venueProduct.value.hasEnquiryEmail);

watch(
	activeUser,
	() => {
		if (activeUser.value) {
			const { firstName, lastName, email } = activeUser.value;
			enquiry.firstName = firstName;
			enquiry.lastName = lastName;
			enquiry.email = !email?.includes("@privaterelay.appleid.com") ? email : "";
		}
	},
	{ immediate: true },
);

onUnmounted(() => {
	queryString.remove("enquiryStage");
});

async function submitEnquiry() {
	const updatedEnquiry = { ...enquiry, phone: enquiry.phone.number ? enquiry.phone : null };
	const { success, errors } = await model.mutations.VenueProductEnquiry({ venueProductId: venueProductId.value, data: updatedEnquiry });
	queryString.enquiryStage = success ? ENQUIRY_STAGE.SUCCESS : ENQUIRY_STAGE.ERROR;
	const redactedEnquiry = { ...enquiry, email: null, phone: null };
	tracking.venueProductEnquirySent({ venue: props.venue, venueProduct: props.venueProduct, success, enquiry: redactedEnquiry });
	if (!success && errors?.length > 0) {
		logger.log(loggingMessages.venueProductEnquiryFailed, { id: venueProductId.value, name: venueProduct.value.name, errors });
	}
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.venue-product-enquiry {
	@include scrollable-content;

	.venue-product-name {
		margin: $spacing 0;
		text-align: center;
		font-size: $text-size-primary;
		font-weight: $text-bold-primary;
	}

	.enquiry-result {
		justify-self: center;
		max-width: 280px;
		margin: calc($spacing * 5) 0;
		text-align: center;
		font-size: $text-size-header-secondary;
		font-weight: $text-bold-primary;
	}
}
</style>
