import { unref } from "vue";
import { Browser } from "@capacitor/browser";

import { usePlatform } from "../domain/platform.js";

export async function openExternalLink({ url, replaceUrl = false, shouldOpenInNewWindow = true, isFileLink = false, browserDialog, browserDialogTitle }) {
	if (shouldOpenInNewWindow) {
		const platform = usePlatform();
		if (browserDialog && !isFileLink) {
			browserDialog.open({ url, title: browserDialogTitle });
		} else if (platform.isNative && !isFileLink) {
			await Browser.open({ url });
		} else {
			window.open(url, "_blank");
		}
	} else {
		if (unref(replaceUrl)) {
			window.location.replace(url);
		} else {
			window.location.href = url;
		}
	}
}
