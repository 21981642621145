export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "ApplyChanges" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	async execute(changes) {
		const accessToken = await this.authentication.getAccessToken();

		const { data, errors } = await this.api.mutations.applyChanges({ changes: changes.map(({ typeName, id, data: _data }) => ({ typeName, id, data: _data })) }, { accessToken });

		const results = changes.map((change) => {
			const changeResult = data?.applyChanges?.changes?.find(({ change: { id: changeId } }) => changeId === change.id);
			const changeIndexInResults = data?.applyChanges?.changes?.indexOf(changeResult) ?? null;
			const error = changeIndexInResults !== null ? errors?.find(({ path }) => path.join(".") === `applyChanges.changes.${changeIndexInResults}.error`) : errors;
			return { change, changeResult, success: changeResult?.success, error };
		});

		return results;
	}
}
