<template>
	<div class="venue-booking-stage-collect-customer-details">
		<VenueHeader :venue="venue" />
		<loading-message v-if="props.isMakingReservation" :full-screen="true">Completing booking, please wait...</loading-message>
		<loading-message v-else-if="props.isCancellingLock" :full-screen="true">Going back, please wait...</loading-message>
		<div v-else-if="timeRemaining > 0" class="customer-details-section">
			<div class="message">
				<span class="reservation-time">{{ formattedReservationDate }} at {{ formattedReservationTime }} for {{ props.partySize }}</span>
			</div>
			<div class="countdown-timer">
				You have <em>{{ formattedTimeRemaining }}</em> to complete your booking
			</div>
			<EntityForm
				v-slot="{ bind, on }"
				:sync-state="true"
				:entity="reservationDetails"
				:fields-config="fieldsConfig"
				:type-name="EntityTypeNames.BookingReservationDetailsInput"
				@save="submitReservationDetails"
			>
				<FormLayoutSimple v-bind="{ ...bind, serverErrorMessage: error ?? bind.error }" :show-labels="true" :is-single-column="true" v-on="on">
					<template #save-button>
						<div class="save-button-container">
							<div class="privacy-message">
								By clicking 'Complete Booking' you agree to the OpenTable
								<a v-external-link href="https://www.opentable.com/legal/terms-and-conditions" target="_blank">Terms of Use</a> and
								<a v-external-link href="https://www.opentable.com/legal/privacy-policy" target="_blank">Privacy Policy</a>
							</div>
							<button class="push-button-call-to-action" @click="on.save">Complete Booking</button>
							<button class="push-button-cancel" @click="cancelLock({ placement: props.placement, text: 'Back', style: ButtonStyles.STANDARD, action: ButtonActions.BOOKING_CANCEL_TIME_SLOT })">
								Back
							</button>
						</div>
					</template>
				</FormLayoutSimple>
			</EntityForm>
		</div>
		<div v-else class="reservation-lock-expired">
			Sorry, you ran out of time to complete your booking. Please try again.
			<a class="push-button-primary" @click="cancelLock({ placement: props.placement, text: 'Back', style: ButtonStyles.STANDARD, action: ButtonActions.BOOKING_CANCEL_TIME_SLOT })">Back</a>
		</div>
	</div>
</template>

<script setup>
import { computed, inject, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
dayjs.extend(duration);
dayjs.extend(utc);

import { EntityTypeNames, ButtonStyles, ButtonActions } from "../../constants/index.js";

import EntityForm from "../forms/EntityForm.vue";
import FormLayoutSimple from "../forms/FormLayoutSimple.vue";
import VenueHeader from "./VenueHeader.vue";

const props = defineProps({
	venueBooking: {
		type: Object,
		required: true,
	},
	isMakingReservation: {
		type: Boolean,
		required: true,
	},
	isCancellingLock: {
		type: Boolean,
		default: false,
	},
	error: {
		type: String,
		required: false,
		default: null,
	},
	reservationToken: {
		type: String,
		required: true,
	},
	reservationTokenExpiresAt: {
		type: String,
		required: true,
	},
	timeSlot: {
		type: String,
		required: true,
	},
	partySize: {
		type: String,
		required: true,
	},
	isCreditCardRequired: {
		type: Boolean,
		required: true,
	},
	placement: {
		type: String,
		required: true,
	},
});

const emit = defineEmits(["reservationDetailsSubmitted", "lockCancelled", "clearError"]);

const currentEnvironment = inject("currentEnvironment");

const reservationDetails = reactive({
	firstName: "",
	lastName: "",
	email: "",
	phone: {
		number: "",
		countryCode: "UK",
	},
	creditCard: {
		number: "",
		expiryMonth: "",
		expiryYear: "",
		cvc: "",
	},
	specialRequest: "",
});
const fieldsConfig = reactive({
	creditCard: { isHidden: computed(() => !props.isCreditCardRequired) },
});

const timeRemaining = ref(null);

const loggedInUser = computed(() => currentEnvironment.value?.loggedInUser.value);
const formattedTimeRemaining = computed(() => (timeRemaining.value !== null ? dayjs.duration(timeRemaining.value).format("mm:ss") : null));
const formattedReservationTime = computed(() => (props.timeSlot ? dayjs.utc(props.timeSlot).format("h:mm A") : null));
const formattedReservationDate = computed(() => (props.timeSlot ? dayjs.utc(props.timeSlot).format("dddd, MMMM D YYYY") : null));
const reservationTokenExpiresAt = computed(() => (props.reservationTokenExpiresAt ? dayjs(props.reservationTokenExpiresAt) : null));
const venue = computed(() => props.venueBooking.venue.value);

watch(
	loggedInUser,
	() => {
		if (loggedInUser.value) {
			const { firstName, lastName, email } = loggedInUser.value;
			reservationDetails.firstName = firstName;
			reservationDetails.lastName = lastName;
			reservationDetails.email = !email?.includes("@privaterelay.appleid.com") ? email : "";
		}
	},
	{ immediate: true },
);

let intervalId = null;
onMounted(() => {
	updateTimer();
	intervalId = setInterval(updateTimer, 1000);
});

onUnmounted(() => {
	clearInterval(intervalId);
});

function updateTimer() {
	const now = dayjs();
	const diff = reservationTokenExpiresAt.value.diff(now);
	if (diff <= 0) {
		clearInterval(intervalId);
		timeRemaining.value = 0;
	} else {
		timeRemaining.value = diff;
	}
}

function submitReservationDetails(updatedReservationDetails) {
	emit(
		"reservationDetailsSubmitted",
		{
			...updatedReservationDetails,
			creditCard: props.isCreditCardRequired ? updatedReservationDetails.creditCard : null,
		},
		{
			button: {
				placement: props.placement,
				text: "Complete Booking",
				style: ButtonStyles.CALL_TO_ACTION,
				action: ButtonActions.BOOKING_COMPLETE,
			},
		},
	);
}

function cancelLock({ placement, text, style, action } = {}) {
	emit("lockCancelled", { button: { placement, text, style, action } });
}

// function clearError() {
// 	emit("clearError");
// }
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-booking-stage-collect-customer-details {
	display: flex;
	flex-direction: column;
	gap: calc($spacing * 1.5);

	.customer-details-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing);
		margin-bottom: calc($spacing / 2);

		.message {
			text-align: center;
			// max-width: 90%;
			font-size: $text-size-primary;
		}

		.countdown-timer {
			color: $text-color-secondary;
			font-size: $text-size-primary;
			text-align: center;
			// max-width: 90%;
			margin-bottom: calc($spacing / 2);

			> em {
				font-style: normal;
				font-weight: $text-bold-primary;
			}
		}

		// .customer-details-form {
		// 	display: grid;
		// 	width: 100%;
		// 	gap: calc($spacing / 2);
		// 	grid-template-columns: auto 1fr;

		// 	.credit-card-expiry {
		// 		display: flex;
		// 		gap: calc($spacing/2);
		// 	}
		// }

		.save-button-container {
			// margin-top: calc($spacing);

			.privacy-message {
				font-size: $text-size-quinary;
				text-align: center;
				max-width: 240px;
				margin-bottom: calc($spacing / 2);

				a {
					// font-weight: $text-bold-primary;
					text-decoration: underline;
				}
			}
		}
	}

	.reservation-lock-expired {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc($spacing * 1.5);
		font-size: $text-size-secondary;
		text-align: center;
	}
}
</style>
