<template>
	<div class="venue-product-enquiry-page">
		<loading-message-with-error v-if="queries.isLoading || queries.error" class="top-loading-message" :error="queries.error" :retry="queries.retry" :full-screen="true">
			Loading place, please wait...
		</loading-message-with-error>
		<error-message v-else-if="!venue" :full-screen="true">No place found</error-message>
		<error-message v-else-if="!venueProduct" :full-screen="true">No event found</error-message>
		<VenueProductEnquiry v-else :venue="venue" :venue-product="venueProduct" />
	</div>
</template>

<script setup>
import { computed, inject } from "vue";

import { combineQueries, useRouteParams } from "../helpers/index.js";
import { useUser } from "../composables/index.js";

import VenueProductEnquiry from "../components/venue/VenueProductEnquiry.vue";

const model = inject("model");

const { isSignedIn } = useUser();
const routeParams = useRouteParams();

const venueId = computed(() => routeParams.venueId);
const venueProductId = computed(() => routeParams.venueProductId);

const queries = combineQueries({
	venueDetail: model.queries.GetVenueDetail({ venueId, isSignedIn }),
});

const venue = computed(() => queries.model?.venue);
const venueProduct = computed(() => venue.value?.products?.find((product) => product.id === venueProductId.value));
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.venue-product-enquiry-page {
	height: 100%;
}
</style>
