export default class {
	constructor({ logger, modelBuilder, api, authentication }) {
		this.logger = logger.nested({ name: "VenueProductEnquiry" });
		this.modelBuilder = modelBuilder;
		this.api = api;
		this.authentication = authentication;
	}

	async execute({ venueProductId, data }) {
		const response = await this.api.mutations.venueProductEnquiry(
			{ venueProductId, data },
			{ accessToken: this.authentication.isAuthenticated.value ? this.authentication.getAccessToken : undefined },
		);

		const errors = response.errors;
		if (errors?.length > 0) {
			return { success: false, errors: errors.map(({ message }) => message) };
		} else {
			if (!response.data.venueProductEnquiry) {
				return { success: false, errors: [{ message: "Unspecified error making enquiry" }] };
			} else {
				return { success: true };
			}
		}
	}
}
