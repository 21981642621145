<template>
	<a :href="props.href" @click="linkClicked"><slot /></a>
</template>

<script setup>
import { inject } from "vue";

const props = defineProps({
	href: {
		type: String,
		required: true,
	},
	browserTitle: {
		type: String,
		default: null,
	},
});

const browserDialogRef = inject("browserDialogRef");
const persistentQueries = inject("persistentQueries");

async function linkClicked(event) {
	event.preventDefault();
	const url = persistentQueries.buildUrl({ url: props.href });
	browserDialogRef.value.open({ url, title: props.browserTitle });
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";
</style>
