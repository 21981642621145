import { helpers, maxLength, minLength, required, numeric } from "@vuelidate/validators";

import { EntityTypeNames } from "../../../constants/index.js";

import FormFieldInput from "../../../components/forms/FormFieldInput.vue";
import FormFieldCreditCard from "../../../components/forms/FormFieldCreditCard.vue";
import FormFieldPhone from "../../../components/forms/FormFieldPhone.vue";

export default class BookingReservationDetailsInputType {
	static typeName = EntityTypeNames.BookingReservationDetailsInput;

	constructor({ addChange }) {
		this.addChange = addChange;

		this.fields = [
			{
				name: "firstName",
				component: FormFieldInput,
				label: "First Name",
				placeholder: "Enter your first name",
				validation: { required: helpers.withMessage("Please enter a first name", required) },
			},
			{
				name: "lastName",
				component: FormFieldInput,
				label: "Last Name",
				placeholder: "Enter your last name",
				validation: { required: helpers.withMessage("Please enter a last name", required) },
			},
			{
				name: "email",
				component: FormFieldInput,
				label: "Email",
				placeholder: "Enter your email address",
				validation: { required: helpers.withMessage("Please enter an email address", required) },
			},
			{
				name: "phone",
				component: FormFieldPhone,
				label: "Phone Number",
				placeholder: "Enter your phone number",
				validation: {
					number: {
						numeric: helpers.withMessage("Your phone number must contain only numbers", numeric),
						required: helpers.withMessage("Please enter a phone number", required),
						minLength: helpers.withMessage("Your phone number must be valid", minLength(6)),
					},
				},
			},
			{
				name: "specialRequest",
				component: FormFieldInput,
				label: "Special Request",
				placeholder: "Add a special request (optional)",
			},
			{
				name: "creditCard",
				component: FormFieldCreditCard,
				label: "Credit Card",
				validation: {
					number: {
						required: helpers.withMessage("Please enter a credit card number", required),
						minLength: helpers.withMessage("Your credit card number must be a valid credit card number", minLength(12)),
						maxLength: helpers.withMessage("Your credit card number must be a valid credit card number", maxLength(19)),
					},
					expiryMonth: {
						required: helpers.withMessage("Please enter a credit card expiry month", required),
						minLength: helpers.withMessage("Your credit card expiry month must be 2 digits", minLength(2)),
					},
					expiryYear: {
						required: helpers.withMessage("Please enter a credit card expiry year", required),
						minLength: helpers.withMessage("Your credit card expiry year must be 2 digits", minLength(2)),
					},
					cvc: {
						required: helpers.withMessage("Please enter a credit card cvc", required),
						minLength: helpers.withMessage("Your credit card cvc must be a valid credit card cvc", minLength(3)),
						maxLength: helpers.withMessage("Your credit card cvc must be a valid credit card cvc", maxLength(4)),
					},
				},
			},
		];
	}
}
