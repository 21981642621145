import { ErrorNested } from "./ErrorNested.js";

export class ApiRequestMutationError extends ErrorNested {
	constructor(message, error, extensions) {
		super("ApiRequestMutationError", `An error occurred calling the API mutation: ${message}`, {
			exception: error,
			code: "API_REQUEST_MUTATION_ERROR",
			...extensions,
		});
	}
}
