<template>
	<div :class="{ 'form-field-container': true, 'error': validation?.$errors?.length > 0 }">
		<textarea
			v-if="props.isMultiLine"
			v-model="fieldState"
			class="form-field"
			:placeholder="props.placeholder"
			:disabled="props.isReadOnly"
			:class="{ disabled: props.isReadOnly }"
			:rows="props.lineCount"
			@change="onStateChanged"
		/>
		<div v-else :class="{ 'form-field': true, 'disabled': props.isReadOnly }">
			<span v-if="props.prefix" class="prefix">{{ props.prefix }}</span>
			<input v-model="fieldState" :placeholder="props.placeholder" :disabled="props.isReadOnly" @change="onStateChanged" />
		</div>
		<FormFieldErrors :validation="validation" />
	</div>
</template>

<script setup>
import { computed, ref, toRef, watch } from "vue";

import FormFieldErrors from "./FormFieldErrors.vue";

const props = defineProps({
	dataType: {
		type: Function,
		required: true,
	},
	validation: {
		type: Object,
		required: true,
	},
	state: {
		type: [String, Number],
		default: "",
	},
	placeholder: {
		type: String,
		default: "",
	},
	isReadOnly: {
		type: Boolean,
		default: false,
	},
	isMultiLine: {
		type: Boolean,
		default: false,
	},
	lineCount: {
		type: Number,
		default: 3,
	},
	prefix: {
		type: String,
		default: null,
	},
});

const emits = defineEmits(["change"]);

const fieldState = ref();
watch(
	toRef(props, "state"),
	() => {
		fieldState.value = props.state;
	},
	{ immediate: true },
);

const validation = computed(() => props.validation);

const onStateChanged = (event) => {
	let formattedValue = event.target.value ? props.dataType(event.target.value) : event.target.value;
	if (isNaN(formattedValue)) {
		formattedValue = event.target.value;
	}
	emits("change", { value: formattedValue });
};
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.form-field-container {
	display: flex;
	flex-direction: column;
	gap: calc($spacing / 4);
	min-width: 50px;
	// max-width: 300px;

	input,
	textarea {
		display: block;
		border: none;

		&::placeholder {
			color: $text-color-tertiary;
		}

		&:focus {
			outline: none;
		}
	}

	.form-field {
		display: flex;
		padding: calc($spacing/1.5) $spacing;
		border-radius: $border-radius-primary;
		border: $border-size-primary solid $border-color-secondary;
		box-sizing: border-box;

		.prefix {
			position: relative;
			top: 3px;
			color: $text-color-secondary;
			font-size: $text-size-quaternary;
			line-height: $text-size-primary;
			margin-right: calc($spacing / 3);
		}

		&.disabled {
			color: $text-color-tertiary;
			border: $border-size-primary solid $border-color-primary;
		}
	}
}
</style>
