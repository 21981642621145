<template>
	<button :class="{ 'venue-product-button': true, 'push-button-call-to-action': !hasIcon }" @click="buttonClicked">
		<img v-if="hasIcon" :src="venueProductType.iconUrl" class="icon" />
		{{ venueProductType.displayName }}
	</button>
</template>

<script setup>
import { computed } from "vue";
import { safeHapticsVibrate } from "../../helpers/index.js";

const props = defineProps({
	venueProductType: {
		type: Object,
		required: true,
	},
	venueProductTypes: {
		type: Array,
		required: true,
	},
	venueProductDialogRef: {
		type: Object,
		default: null,
	},
});

const venueProductType = computed(() => props.venueProductType);
const hasIcon = computed(() => !!venueProductType.value.iconUrl);

function buttonClicked() {
	safeHapticsVibrate({ duration: 20 });
	props.venueProductDialogRef?.open({ venueProductType: props.venueProductType.name });
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-product-button {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: calc($spacing / 3);
	font-size: $text-size-tertiary !important;
	color: $text-color-primary;
	font-weight: $text-bold-primary;
	white-space: nowrap;
	cursor: pointer;

	border-radius: $border-radius-quinary;
	padding: calc($spacing / 2) calc($spacing);
	box-sizing: border-box;
	background-color: $background-color-quinary;

	// transition: transform 0.3s ease-in-out;
	// &:hover {
	// 	transform: scale(110%);
	// }

	&.push-button-call-to-action {
		padding: 0 $spacing;
		width: 80px;
		height: 38px;
		white-space: wrap;
	}
}

.icon {
	// width: 20px;
	height: 24px;
}
</style>
