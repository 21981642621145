<template>
	<div class="user-action-container">
		<template v-if="showAction">
			<div class="icon-container"><img :src="iconUrl" class="icon" /></div>
			<div class="message">{{ message }}</div>
			<template v-if="props.userAction === SuggestedUserAction.SIGN_IN">
				<div class="premium-buttons">
					<button data-signup class="push-button-primary" @click="signUpOnClick">Sign Up</button>
					<span>or</span>
					<button data-login class="push-button-primary" @click="loginOnClick">Log In</button>
				</div>
				<div class="footer-message">
					<div>{{ footerMessage }}</div>
					<router-link :to="{ name: PageNames.PRIVACY_POLICY }" class="link-button-primary">Privacy Policy</router-link>
				</div>
			</template>
			<LoadingMessageWithError v-else-if="premiumProductsQuery.error || premiumProductsQuery.isLoading" :error="premiumProductsQuery.error" :retry="premiumProductsQuery.retry">
				Loading, please wait...
			</LoadingMessageWithError>
			<template v-else-if="props.userAction === SuggestedUserAction.UPGRADE_PREMIUM">
				<div class="premium-buttons">
					<PremiumPurchaseButton
						v-if="monthlyProduct"
						name="monthly"
						:premium-products="premiumProducts"
						:premium-product-interval="PremiumProductInterval.MONTH"
						@click="premiumPurchaseButtonOnClick"
					/>
					<PremiumPurchaseButton v-if="annualProduct" name="annual" :premium-products="premiumProducts" :premium-product-interval="PremiumProductInterval.YEAR" @click="premiumPurchaseButtonOnClick" />
				</div>
				<div class="footer-message">
					<!-- <div>Want to know more? <button class="link-button-primary" @click="discoverPremiumButtonOnClick">Discover Premium</button>.</div> -->
					<div>Already Premium? <button class="link-button-primary" @click="loginOnClick">Click here to Log In</button></div>
				</div>
			</template>
			<template v-else>Unable to show dialog</template>
		</template>
		<template v-else> No feature and/or user action provided </template>
	</div>
</template>

<script setup>
import { computed, inject } from "vue";

import { PageNames, PremiumFeature, PremiumProductInterval, SuggestedUserAction } from "../constants/index.js";
import { getCurrentPageRelativeUrl, useRouteParams } from "../helpers/index.js";

import PremiumPurchaseButton from "./PremiumPurchaseButton.vue";

import DiamondBlack from "../assets/icons/Diamond-Black.svg";
import GlobeBlack from "../assets/icons/globe.svg";
import BeenIcon from "../assets/icons/been.svg";
import FavouriteIcon from "../assets/icons/favourite.svg";
import GoTryIcon from "../assets/icons/go-try.svg";
import ProfileIcon from "../assets/icons/profile.svg";
import ActivityFeedIcon from "../assets/icons/activity.svg";

const FEATURES = {
	[PremiumFeature.PERK]: {
		icon: DiamondBlack,
		[SuggestedUserAction.UPGRADE_PREMIUM]: {
			message: "Upgrade to premium to access all onezone perks",
		},
		[SuggestedUserAction.SIGN_IN]: {
			message: "Sign up or log in to redeem this onezone perk",
			footerMessage: "Create a free account to redeem this perk",
		},
	},
	[PremiumFeature.CITY]: {
		icon: GlobeBlack,
		[SuggestedUserAction.UPGRADE_PREMIUM]: {
			message: "Upgrade to premium to access all onezone cities",
		},
	},
	[PremiumFeature.LAUNCH]: {
		icon: DiamondBlack,
		[SuggestedUserAction.UPGRADE_PREMIUM]: {
			message: "Upgrade to premium to access all onezone exclusives",
		},
		[SuggestedUserAction.SIGN_IN]: {
			message: "Sign up or log in to book this onezone preview",
			footerMessage: "Create a free account to book this preview",
		},
	},
	[PremiumFeature.BEEN]: {
		icon: BeenIcon,
		[SuggestedUserAction.SIGN_IN]: {
			message: "Sign up or log in to add this place to your Been List",
			footerMessage: "Create a free account to tick this place off",
		},
	},
	[PremiumFeature.FAVOURITES]: {
		icon: FavouriteIcon,
		[SuggestedUserAction.SIGN_IN]: {
			message: "Sign up or log in to save this place to your Favourites List",
			footerMessage: "Create a free account to remember your favourites",
		},
	},
	[PremiumFeature.GO_TRY]: {
		icon: GoTryIcon,
		[SuggestedUserAction.SIGN_IN]: {
			message: "Sign up or log in to save this place to your Go Try List",
			footerMessage: "Create a free account to remember the places you want to try",
		},
	},
	[PremiumFeature.PROFILE]: {
		icon: ProfileIcon,
		[SuggestedUserAction.SIGN_IN]: {
			message: "Sign up or log in to save places you've been, love or want to go try",
			footerMessage: "Create a free account now",
		},
	},
	[PremiumFeature.ACTIVITY_FEED]: {
		icon: ActivityFeedIcon,
		[SuggestedUserAction.SIGN_IN]: {
			message: "Sign up or log in to start following your friends",
			footerMessage: "Create a free account now",
		},
	},
};

const props = defineProps({
	feature: {
		type: String,
		default: null,
	},
	userAction: {
		type: String,
		default: null,
	},
});

const emit = defineEmits(["click"]);

// const router = useRouter();
const routeParams = useRouteParams();
const model = inject("model");
const currentEnvironment = inject("currentEnvironment");
// const mobileApp = inject("mobileApp");

const showAction = computed(() => props.feature && props.userAction);
const cityName = computed(() => routeParams.cityName);
const iconUrl = computed(() => FEATURES[props.feature]?.icon);
const config = computed(() => (props.feature && props.userAction ? FEATURES[props.feature][props.userAction] : null));
const message = computed(() => config.value?.message);
const footerMessage = computed(() => config.value?.footerMessage);

const premiumProductsQuery = model.queries.GetPremiumProducts({ cityName });
const premiumProducts = computed(() => premiumProductsQuery.model?.products ?? []);
const monthlyProduct = computed(() => premiumProducts.value.find((product) => product.interval === PremiumProductInterval.MONTH));
const annualProduct = computed(() => premiumProducts.value.find((product) => product.interval === PremiumProductInterval.YEAR));

// async function discoverPremiumButtonOnClick() {
// 	emit("click");
// 	if (router.currentRoute.value.name !== "Premium") {
// 		router.push(urlBuilder(PageNames.PERKS));
// 	}
// }

async function signUpOnClick() {
	emit("click");
	await currentEnvironment.value.createAccount({ returnTo: getCurrentPageRelativeUrl() });
}
async function loginOnClick() {
	emit("click");
	await currentEnvironment.value.login({ returnTo: getCurrentPageRelativeUrl() });
}

async function premiumPurchaseButtonOnClick() {
	emit("click");
}
</script>

<style scoped lang="scss">
@import "../assets/styles/variables_new.scss";

.user-action-container {
	background-color: $background-color-primary;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: calc($spacing * 1.2);
	// min-width: 300px;
	// width: 400px;
	// max-width: 420px;
	border: none;

	.icon-container {
		height: 30px;
		.icon {
			width: 30px;
		}
	}

	.message {
		max-width: 250px;
		font-size: $text-size-header-secondary;
		font-weight: $text-bold-secondary;
		text-align: center;
	}

	.premium-buttons {
		display: flex;
		flex-direction: row;
		gap: calc($spacing/1.5);
		align-items: center;
	}

	.footer-message {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		gap: calc($spacing);
		font-size: $text-size-secondary;
	}
}
</style>
