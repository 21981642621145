<template>
	<VenueDetailSection :title="title">
		<VenueProductButton
			v-for="venueProductType in venueProductTypesWithIconToShow"
			:key="venueProductType.id"
			:venue-product-types="props.venueProductTypes"
			:venue-product-type="venueProductType"
			:venue-product-dialog-ref="props.venueProductDialogRef"
		/>
	</VenueDetailSection>
</template>

<script setup>
import { computed } from "vue";

import { pluralize } from "../../helpers/index.js";

import VenueDetailSection from "./VenueDetailSection.vue";
import VenueProductButton from "./VenueProductButton.vue";

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
	venueProductTypes: {
		type: Array,
		required: true,
	},
	venueProductDialogRef: {
		type: Object,
		default: null,
	},
});

const venueProducts = computed(() => props.venue.products);
const venueProductTypesWithIconToShow = computed(() => props.venueProductTypes.filter((type) => venueProducts.value.find((product) => type.name === product.type) && type.iconUrl));
const title = computed(() => pluralize(venueProductTypesWithIconToShow.value.length, "Spotlight Moment"));
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";
</style>
