export const persistQueryAcrossNavigations = (queryNames, { router, defaultEnvironmentName } = {}) => {
	router.persistedQueryNames = router.persistedQueryNames || {};
	queryNames.forEach((queryName) => {
		router.persistedQueryNames[queryName] = true;
	});

	router.beforeEach((to, from, next) => {
		const query = getQuery({ to, from });
		if (query) {
			next({ ...to, query: { ...query, ...to.query } });
		} else {
			next();
		}
	});

	return {
		getQuery,
		buildUrl,
	};

	function getQuery({ to, from } = {}) {
		if (!from) {
			from = router.currentRoute.value;
		}

		let query = null;
		queryNames.forEach((queryName) => {
			if (from.query[queryName] && typeof to?.query[queryName] === "undefined") {
				if (!query) {
					query = {};
				}
				query[queryName] = from.query[queryName];
			}
		});
		return query;
	}

	function buildUrl({ url, returnTo } = {}) {
		const query = Object.fromEntries(Object.entries(getQuery() ?? {}).filter(([key, value]) => (value === defaultEnvironmentName.toLowerCase() && key === "environment" ? false : true)));

		const redirect_uri = new URL(url);
		redirect_uri.search = new URLSearchParams(
			Object.entries({
				...query,
				returnTo,
			}).filter(([, value]) => value !== undefined),
		).toString();

		return redirect_uri;
	}
};
