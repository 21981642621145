<template>
	<div :class="{ 'map-container': true, 'is-loading': queries.isLoading }">
		<LoadingMessageWithError v-if="isLoadingFirstTimeForCity || queries.error" class="loading-message" :error="queries.error">
			Loading {{ formattedCityName }}, please wait...
		</LoadingMessageWithError>
		<div v-else-if="isAllowedToViewCity || queries.isLoading" class="map-wrapper">
			<div class="map-ui">
				<div class="top-section">
					<div class="search-box-container">
						<SafeArea />
						<SearchBox ref="searchBoxRef" class="search-box" v-bind="searchBoxBind" :drop-shadow="true" v-on="searchBoxOn">
							<template #results>
								<StandardSearchResults :result-types="resultTypes" v-bind="searchResultsBind" />
							</template>
							<template #footer>
								<button class="push-button-primary" @click="hideResults(true)">Show Map</button>
							</template>
						</SearchBox>
					</div>
					<TransitionAnimation :name="AnimationNames.FADE" duration="0.1s" enter-delay="0.2s">
						<div v-show="showMapUI" class="search-context-container">
							<StandardSearchContext :has-drop-shadow="true" :show-result-count="!queries.isLoading" :show-is-loading="queries.isLoading" />
						</div>
					</TransitionAnimation>
				</div>
				<TransitionAnimation :name="AnimationNames.SLIDE_IN_OUT_BOTTOM" duration="0.1s">
					<div v-show="showMapUI" class="bottom-section">
						<div class="actions">
							<button :class="{ 'move-to-user-location': true, 'disabled': !geoLocation.isAllowed }" @click="moveToUserLocation">
								<FontAwesomeIcon icon="location-crosshairs" class="icon" />
							</button>
							<!-- <div v-if="queries.isLoading" class="search-status">Searching...</div> -->
							<button v-if="!queries.isLoading && showSearchCityButton" class="search-city-button" @click="searchCityClicked">Search {{ formattedCityName }}</button>
							<button v-else-if="!queries.isLoading && showSearchHereButton" class="search-here-button" @click="searchHereClicked">Search Here</button>
						</div>
						<VenueList v-if="!queries.isLoading" :selected-venue-id="selectedVenueId" :user-lists="userLists" :venues="venueResults" class="venue-list" @venue-change="slideVenueChanged" />
					</div>
				</TransitionAnimation>
			</div>
			<!-- <KeepAlive> -->
			<!-- <VenueMap
				v-if="showMap && featureSwitchManager.isActive(FeatureSwitchNames.NativeMap)"
				ref="googleMapRef"
				:venues="venueResults"
				:user-lists="userLists"
				:selected-venue-id="selectedVenueId"
				:center="center"
				:zoom-level="zoomLevel"
				:search-location="searchLocation"
				:fit-to-bounds="fitToBounds"
				:show-zoom-controls="showMapUI"
				@venue-change="mapVenueChanged"
				@zoom-change="mapZoomChanged"
				@center-change="mapCenterChanged"
			/> -->
			<GoogleMap
				v-if="showMap"
				ref="googleMapRef"
				:venues="venueResults"
				:user-lists="userLists"
				:selected-venue-id="selectedVenueId"
				:center="center"
				:zoom-level="zoomLevel"
				:search-location="searchLocation"
				:fit-to-bounds="fitToBounds"
				:show-zoom-controls="showMapUI"
				@venue-change="mapVenueChanged"
				@zoom-change="mapZoomChanged"
				@center-change="mapCenterChanged"
			/>
			<!-- </KeepAlive> -->
		</div>
		<PremiumUpgradeMessage v-else :feature="PremiumFeature.CITY" :user-action="suggestedUserAction">
			Only premium members are allowed to view the map of {{ formattedCityName }}.<br /><br />
			Access more cities with onezone premium
		</PremiumUpgradeMessage>
		<Dialog ref="confirmDialogRef" enter-name="apply" escape-name="ignore">
			<DialogSimpleLayout :has-close-button="false">
				<DialogMessage>
					You have {{ pluralizeResultCount(pendingFilterCount, "filter") }} which {{ pendingFilterCount > 1 ? "have" : "has" }} not yet been applied. What would you like to do?
				</DialogMessage>
				<DialogButtons>
					<DialogButton name="ignore">Ignore</DialogButton>
					<DialogButton name="apply">Apply Now</DialogButton>
				</DialogButtons>
			</DialogSimpleLayout>
		</Dialog>
	</div>
</template>

<script setup>
import { computed, inject, onActivated, onMounted, reactive, ref, watch, toRef, provide } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onClickOutside } from "@vueuse/core";

import { pluralizeResultCount, useIsRouteActive, useRouteParams, computeDistanceBetween, useSearchResults } from "../helpers/index.js";
import { PremiumFeature, AnimationNames, ResultTypes } from "../constants/index.js";
import { useQueryString, toArray, fromArray } from "../functions/query-string/index.js";
// import { FeatureSwitchNames } from "../domain/index.js";
import loggingMessages from "./Map.logging-messages.js";

import StandardSearchResults from "./StandardSearchResults.vue";
import PremiumUpgradeMessage from "./PremiumUpgradeMessage.vue";
import SearchBox from "../components/SearchBox.vue";
import GoogleMap from "../components/GoogleMap.vue";
// import VenueMap from "./VenueMap.vue";
import VenueList from "../components/VenueList.vue";
import Dialog from "../components/Dialog.vue";
import DialogSimpleLayout from "./DialogSimpleLayout.vue";
import DialogMessage from "./DialogMessage.vue";
import DialogButtons from "./DialogButtons.vue";
import DialogButton from "./DialogButton.vue";
import StandardSearchContext from "./StandardSearchContext.vue";

const HARD_CODED_CITY_LOCATIONS = {
	london: { lat: 51.5129, lng: -0.1467, radius: 20000 },
	paris: { lat: 48.85652, lng: 2.351712, radius: 20000 },
	amsterdam: { lat: 52.3546656, lng: 4.9039604, radius: 20000 },
	lisbon: { lat: 38.7436266, lng: -9.1602032, radius: 20000 },
	barcelona: { lat: 41.3926679, lng: 2.1401891, radius: 20000 },
	berlin: { lat: 52.519499, lng: 13.403696, radius: 20000 },
	rome: { lat: 41.9100711, lng: 12.5359979, radius: 20000 },
	margate: { lat: 51.3893, lng: 1.3806, radius: 20000 },
	brighton: { lat: 50.8194, lng: -0.1364, radius: 20000 },
	copenhagan: { lat: 55.6712674, lng: 12.5938239, radius: 20000 },
	manchester: { lat: 53.4782, lng: -2.2415, radius: 20000 },
};

const RESULT_PAGE_SIZE = 60;

const isAppHydrated = inject("isAppHydrated");
const platform = inject("platform");
const geoLocation = inject("geoLocation");
// const featureSwitchManager = inject("featureSwitchManager");
const logger = inject("logger").nested({ name: "Map" });

const resultTypes = [
	{ type: ResultTypes.GOOGLE_PLACES, resultArgs: { routerLinkEnabled: false } },
	{ type: ResultTypes.LISTS, resultArgs: { routerLinkEnabled: false } },
	{ type: ResultTypes.TAGS, resultArgs: { routerLinkEnabled: false } },
	{
		type: ResultTypes.VENUES,
		sectionArgs: (venues) => ({ subHeader: computed(() => (venues.length > 0 ? "(displayed on map)" : null)) }),
		resultArgs: (venue) => ({ routerLink: buildLinkToVenuePlaceSearch(venue) }),
	},
	{ type: ResultTypes.ZONES, resultArgs: { routerLinkEnabled: false } },
];

const isMounted = ref(false);
const searchBoxRef = ref(null),
	googleMapRef = ref(null),
	confirmDialogRef = ref(null);
const currentCity = ref(null);
const isFirstSearchForCity = ref(true);
const routeParams = useRouteParams();
const router = useRouter();
const route = useRoute();
const queryString = useQueryString(
	{
		center: null,
		zoomLevel: null,
		selectedVenueId: null,
		searchLocation: null,
	},
	{ router, route, persist: true },
);
const { isRouteActive } = useIsRouteActive(route.name, { debugLabel: "Map" });

// geoLocation.onInit(() => {
// 	console.log("geoLocation.onInit", geoLocation.isAllowed);
// 	if (geoLocation.isAllowed) {
// 		moveToUserLocation();
// 	}
// });

const center = computed(() =>
	fromArray(queryString.center, [
		["lat", parseFloat],
		["lng", parseFloat],
	]),
);
const zoomLevel = computed(() => (queryString.zoomLevel ? parseFloat(queryString.zoomLevel) : null));
const selectedVenueId = computed(() => queryString.selectedVenueId);
const searchLocation = computed(() =>
	fromArray(queryString.searchLocation, [
		["lat", parseFloat],
		["lng", parseFloat],
	]),
);
const showMap = computed(() => !!(center.value || searchLocation.value));
const fitToBounds = computed(() => !center.value);
const cityLocation = computed(() => HARD_CODED_CITY_LOCATIONS[routeParams.cityName]);
const showMapUI = computed(() => !shouldShowSearchResults.value && !shouldShowSearchFilters.value);

/* TODO this doesn't work as the watch for model above fires whenever any entity is changed (even when map is not active) */
// watch(toRef(routeParams, "cityName"), () => {
// 	isFirstSearchForCity.value = true;
// });

const isLoadingFirstTimeForCity = computed(() => queries.isLoading && isFirstSearchForCity.value);
const centerToSearchLocationDistance = computed(() => {
	if (platform.isServer || !isMounted.value || !center.value || !searchLocation.value || !cityLocation.value) {
		return 0;
	}
	const distance = computeDistanceBetween({ lat: center.value.lat, lng: center.value.lng }, { lat: searchLocation.value.lat, lng: searchLocation.value.lng });
	return distance;
});
const centerToCurrentCityDistance = computed(() => {
	if (platform.isServer || !isMounted.value || !(center.value || searchLocation.value) || !cityLocation.value) {
		return 0;
	}
	const currentLocation = center.value ?? searchLocation.value;
	return computeDistanceBetween({ lat: currentLocation.lat, lng: currentLocation.lng }, { lat: cityLocation.value.lat, lng: cityLocation.value.lng });
});
const showSearchHereButton = computed(() => centerToSearchLocationDistance.value > 200);
const showSearchCityButton = computed(() => centerToCurrentCityDistance.value > cityLocation.value.radius);

const searchResults = useSearchResults(reactive({ cityName: toRef(routeParams, "cityName"), searchLocation, pageSize: RESULT_PAGE_SIZE, confirmDialogRef }));
const {
	formattedCityName,
	suggestedUserAction,
	isAllowedToViewCity,
	hideResults,
	venueResults,
	shouldShowSearchResults,
	shouldShowSearchFilters,
	pendingFilterCount,
	searchBoxOn,
	searchBoxBind,
	searchResultsBind,
	userLists,
	isConfirmingPendingFilterState,
	allQueries: queries,
	// promise,
} = searchResults;

provide("searchResults", searchResults);

watch(toRef(queries, "model"), () => {
	isFirstSearchForCity.value = false;
});

// await promise; /* awaiting the promise stops onActivated from being fired the first time it is loaded, which stops data from loading, so we have disabled this for now */

onClickOutside(searchBoxRef, async () => {
	if (isRouteActive.value && !isConfirmingPendingFilterState.value && (shouldShowSearchResults.value || shouldShowSearchFilters.value)) {
		try {
			logger.log(loggingMessages.onClickOutside);
			await hideResults();
		} catch (error) {
			logger.log(loggingMessages.errorOnClickOutside, { error });
		}
	}
});

onMounted(async () => {
	isMounted.value = true;
});

onActivated(() => {
	if (!searchLocation.value) {
		changeCity(routeParams.cityName);
	}
});

watch(
	toRef(routeParams, "cityName"),
	() => {
		changeCity(routeParams.cityName);
	},
	{ immediate: false },
);

function buildLinkToVenuePlaceSearch(venue) {
	return {
		name: "City/Map",
		params: {
			city: routeParams.cityName,
		},
		query: {
			...route.query,
			showSearchResults: false,
			selectedVenueId: venue.id,
		},
	};
}

function mapCenterChanged(newCenter) {
	queryString.set("center", toArray(newCenter, ["lat", "lng"]));
}

function mapZoomChanged(_zoomLevel) {
	queryString.set("zoomLevel", _zoomLevel);
}

function mapVenueChanged(venueId) {
	selectVenue(venueId);
}

function searchHereClicked() {
	logger.log(loggingMessages.searchHereClicked);
	selectVenue(null);
	queryString.set("searchLocation", toArray(center.value, ["lat", "lng"]));
	queryString.set("center", undefined);
}

function searchCityClicked() {
	logger.log(loggingMessages.searchCityClicked, { city: formattedCityName.value });
	queryString.set("searchLocation", toArray(cityLocation.value, ["lat", "lng"]));
	queryString.set("center", toArray(cityLocation.value, ["lat", "lng"]));
}

function slideVenueChanged(venueId) {
	selectVenue(venueId);
}

function moveToUserLocation() {
	if (geoLocation.isAllowed) {
		googleMapRef.value?.moveToUserLocation();
	} else if (geoLocation.isLoading) {
		/* TODO: show modal */
		alert("still initialising geolocation...");
	} else {
		/* TODO: show modal */
		alert("you need to allow location...");
	}
}

function changeCity(cityName) {
	if (currentCity.value !== cityName || !queryString.searchLocation) {
		const newCityLocation = HARD_CODED_CITY_LOCATIONS[cityName];
		if (!newCityLocation) {
			throw new Error(`City location not found for ${cityName}`);
		}
		currentCity.value = cityName;
		isFirstSearchForCity.value = true;
		// if (!queryString.searchLocation) {
		queryString.set("searchLocation", toArray(newCityLocation, ["lat", "lng"]));
		queryString.set("center", undefined);
		// }
		return;
	}
}

function selectVenue(venueId) {
	queryString.set("selectedVenueId", venueId);
	queryString.set("center", undefined);
}

if (!isAppHydrated.value) {
	// await queries.promise;
}
</script>

<style lang="scss" scoped>
@use "three-dots" with (
	$dot-width: 10px,
	$dot-height: 10px,
	$dot-color: #cccccc
);
@import "../assets/styles/variables_new.scss";

.map-container {
	overflow: auto;
	height: 100%;

	.loading-message {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.map-wrapper {
		position: relative;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;

		.map-ui {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			overflow: hidden;
			pointer-events: none;

			.top-section {
				padding: 0 0 calc($spacing * 2) 0;
				box-sizing: border-box;
				pointer-events: none;
				z-index: 1;
				width: 100%;
				overflow: auto;
				display: flex;
				flex-direction: column;
				align-items: stretch;

				.search-context-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: calc($spacing/ 1.5);

					:deep(.search-context) {
						max-width: min(450px, 90vw);
						pointer-events: all;

						// .search-context-loading {
						// 	:deep(.text) {
						// 		display: flex;
						// 		justify-content: center;
						// 		gap: calc($spacing / 2);
						// 		padding: 0 calc($spacing / 2);
						// 		overflow: visible;

						// 		.animation-wrapper {
						// 			display: flex;
						// 			justify-content: center;
						// 			align-items: center;
						// 			width: 50px;
						// 		}
						// 	}
						// }
					}
				}

				.search-box-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					overflow: auto;

					.search-box {
						pointer-events: all;
						width: 90%;
						max-width: 450px;
					}
				}
			}

			.bottom-section {
				position: relative;
				pointer-events: none;
				z-index: 1;
				width: 100%;
				height: 182px; /* set explicit height so when we hide the venue list things don't move down */
				display: flex;
				gap: $spacing;
				flex-direction: column;
				align-items: center;
				padding-bottom: calc($spacing * 1);

				.actions {
					padding-top: calc($spacing);
					height: 34px;

					.search-here-button,
					.search-city-button {
						@include drop-shadow-primary;
						pointer-events: all;
						min-width: 120px;
						box-sizing: border-box;
						text-align: center;
						background-color: $text-color-primary;
						color: $background-color-primary;
						padding: calc($spacing / 1.5) calc($spacing * 1.5);
						border-radius: $border-radius-quaternary;
						font-size: $text-size-primary;

						&:active {
							background-color: $text-color-secondary;
						}
					}

					.search-status {
						@include drop-shadow-primary;
						width: 120px;
						box-sizing: border-box;
						text-align: center;
						background-color: $background-color-secondary;
						color: $text-color-tertiary;
						padding: calc($spacing / 1.5) calc($spacing * 1.5);
						border-radius: $border-radius-quaternary;
						font-size: $text-size-primary;
					}

					.move-to-user-location {
						pointer-events: all;
						position: absolute;
						right: $spacing;
						top: 16px;
						@include drop-shadow-primary;

						&.disabled {
							.icon {
								color: $text-color-tertiary;
							}
						}

						.icon {
							background-color: $background-color-primary;
							border-radius: 50%;
							color: $text-color-quinary;
							width: 20px;
							height: 20px;
							padding: 6px;
						}
					}
				}

				.venue-list {
					pointer-events: all;
				}
			}
		}
	}
}

@media (min-width: $bp-medium) {
	.map-container {
		padding-bottom: calc($spacing * 2);
		box-sizing: border-box;

		.map-wrapper {
			border-radius: $border-radius-primary;
			overflow: hidden;

			.map-ui {
				.top-section {
					padding: $spacing 0;
				}
				.venue-list {
					max-width: 860px;
				}
			}
		}
	}
}
</style>
