export default {
	usingSSRCachedDataForOperation: {
		level: "verbose",
		message: ({ operationName, variables, environmentName }) => `📦 ${environmentName}:${operationName} Using SSR cached data for operation ${variables}`,
	},
	usingApiDataForOperation: {
		level: "verbose",
		message: ({ operationName, variables, environmentName }) => `📡➡️📦 ${environmentName}:${operationName} Using API data for operation ${variables}`,
	},
	usingRequestCacheDataForOperation: {
		level: "verbose",
		message: ({ operationName, variables, timeRemaining, environmentName }) =>
			`📦 ${environmentName}:${operationName} Using request cache data for operation ${variables} (cache will expire in ${timeRemaining})`,
	},
	apiDataCallForOperationSuccessful: {
		level: "verbose",
		message: ({ operationName, variables, environmentName, durationMS }) => `✅ ${environmentName}:${operationName} Successfully retrieved data from API for operation ${variables} in ${durationMS}`,
	},
	gotApolloClient: {
		level: "debug",
		message: ({ isAuthenticated }) => `Got Apollo client, isAuthenticated: ${isAuthenticated}`,
	},
};
