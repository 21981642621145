<template>
	<div class="admin-info">
		<div v-if="isAdmin" class="admin-content">
			<!-- <one-signal-admin /> -->
			<referrer-details />
			<change-environment />
			<change-stripe-environment />
			<feature-switches />
			<impersonate-user />
			<device-details />
			<entity-store-summary />
		</div>
		<div v-else>
			<error-message>You do not have administrator access</error-message>
		</div>
	</div>
</template>

<script setup>
import { ref, inject, computed, onMounted } from "vue";

import ChangeEnvironment from "../components/admin/ChangeEnvironment.vue";
import ChangeStripeEnvironment from "../components/admin/ChangeStripeEnvironment.vue";
import ImpersonateUser from "../components/admin/ImpersonateUser.vue";
import EntityStoreSummary from "../components/admin/EntityStoreSummary.vue";
import FeatureSwitches from "../components/admin/FeatureSwitches.vue";
import ReferrerDetails from "../components/ReferrerDetails.vue";
import DeviceDetails from "../components/DeviceDetails.vue";

// import OneSignalAdmin from "../components/admin/OneSignalAdmin.vue";

const environmentManager = inject("environmentManager");

const isAdmin = computed(() => {
	return isMounted.value ? environmentManager?.isAdmin.value : false;
});

const isMounted = ref(false);
onMounted(() => {
	isMounted.value = true;
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables_new.scss";

.admin-info {
	display: flex;
	flex-direction: column;
	overflow: auto;

	.admin-header {
		margin-left: $spacing;
	}

	.admin-content {
		display: flex;
		flex-direction: column;
		gap: $spacing;
		overflow: auto;
		box-sizing: border-box;
		padding: $spacing;
		word-wrap: break-word;
	}
}
</style>
