import { ensureArray, entityArray } from "../helpers/index.js";

export class EntityProcessorService {
	constructor({ logger, environmentName, entityProcessors, entityStore }) {
		this.logger = logger.nested({ name: "EntityProcessorService" });
		this.environmentName = environmentName;
		this.entityProcessors = entityProcessors;
		this.entityStore = entityStore;
	}

	processRawData(rawData, summary) {
		const processedData = Object.fromEntries(
			Object.entries(rawData).map(([queryName, queryResults]) => {
				const isQueryResultAnArray = Array.isArray(queryResults);
				const processedEntities = ensureArray(queryResults).map((queryResult) => {
					const processedEntity = this.processEntity(queryResult, {}, summary);
					return processedEntity;
				});

				return [queryName, isQueryResultAnArray ? processedEntities : processedEntities[0]];
			}),
		);

		return processedData;
	}

	mergeArray(newArray, existingArray, index, summary) {
		return this.#insertIntoArray(this.processEntities(newArray, {}, summary), existingArray, index);
	}

	processEntities(entityOrEntities, args, summary) {
		if (!entityOrEntities) {
			return entityArray([]);
		}

		const entities = ensureArray(entityOrEntities);

		const processedEntities = entityArray(entities.map((entity) => this.processEntity(entity, args, summary)));
		return Array.isArray(entityOrEntities) ? processedEntities : processedEntities[0];
	}

	processEntity(entity, args, summary) {
		if (!entity) {
			return entity;
		}
		if (typeof entity === "string" || typeof entity === "number" || typeof entity === "boolean") {
			return entity;
		}
		const typeName = entity?.__typename ?? null;
		if (!typeName) {
			throw new Error(`Entity does not have __typename property, it does have these properties: ${Object.keys(entity).map((key) => key)}`);
		}

		const existingEntity = this.entityStore.getStoredEntity({ id: entity.id, typeName });
		const entityProcessorForType = this.#getEntityProcessorForType({ entityStore: this.entityStore, typeName });
		let processedAndStoredEntity;
		if (entityProcessorForType) {
			processedAndStoredEntity = entityProcessorForType.process({ entity, existingEntity: existingEntity?.value, entityProcessor: this }, args, summary);
			if (processedAndStoredEntity === false) {
				processedAndStoredEntity = this.processObject(entity, args, summary);
			}
		} else {
			const processedEntity = this.processObject(entity, args, summary);
			processedAndStoredEntity = this.entityStore.store(
				{
					id: entity.id,
					typeName,
					entity: processedEntity,
				},
				summary,
			);
		}

		return processedAndStoredEntity;
	}

	processObject(object, args, summary, excludeKeys = []) {
		const typeName = object?.__typename ?? null;
		const entityId = object?.id ?? null;

		// if (typeName && entityId) {
		// 	const previouslyProcessedEntity = summary[typeName]?.ids[entityId]?.entity;
		// 	const previouslyProcessedEntityFingerprint = summary[typeName]?.ids[entityId]?.fingerprint;
		// 	if (previouslyProcessedEntity && previouslyProcessedEntityFingerprint === this.#getFingerprint(object)) {
		// 		return previouslyProcessedEntity;
		// 	}
		// }

		const startTime = performance.now();

		const processedObject = Object.fromEntries(
			Object.entries(object)
				.filter(([key]) => !excludeKeys.includes(key))
				.map(([key, value]) => {
					const shouldProcess = Array.isArray(value) && value.length > 0 ? !!value[0].__typename : !!value?.__typename;
					const isEmptyArray = Array.isArray(value) && value.length === 0;
					const finalValue = shouldProcess ? this.processEntities(value, args, summary) : isEmptyArray ? entityArray(value) : value;

					return [key, finalValue];
				}),
		);

		const endTime = performance.now();
		const duration = endTime - startTime;

		if (typeName && entityId) {
			if (!summary[typeName]) {
				summary[typeName] = {
					ids: {},
				};
			}
			if (!summary[typeName].ids[entityId]) {
				summary[typeName].ids[entityId] = {};
			}
			summary[typeName].ids[entityId].entity = processedObject;
			summary[typeName].ids[entityId].fingerprint = this.#getFingerprint(processedObject);
			summary[typeName].ids[entityId].numberOfTimesParsed = (summary[typeName].ids[entityId]?.numberOfTimesParsed ?? 0) + 1;
			summary[typeName].ids[entityId].parseDuration = (summary[typeName].ids[entityId].parseDuration ?? 0) + duration;
		}

		return processedObject;
	}

	#getFingerprint(object) {
		return Object.keys(object).sort().join(",");
	}

	#insertIntoArray(newArray, existingArray = [], index = 0) {
		newArray.forEach((item, itemIndex) => {
			existingArray[itemIndex + index] = item;
		});

		return existingArray;
	}

	#getEntityProcessorForType({ entityStore, typeName } = {}) {
		const EntityProcessorForType = Object.values(this.entityProcessors).find(({ processor, environmentName }) =>
			processor.doesSupportType && (!environmentName || environmentName === this.environmentName) ? processor.doesSupportType(typeName) : false,
		)?.processor;

		const entityProcessor = EntityProcessorForType ? new EntityProcessorForType({ entityProcessors: this.entityProcessors, entityStore }) : null;
		return entityProcessor;
	}
}
