<template>
	<MiniCard v-if="props.showMini" :text="props.zone.name" :bottom-text="venueCount" :image-src="imageUrl" :image-preview="imagePreview" :router-link="routerLink" :image-size="props.imageSize">
		<template v-if="hasDefaultSlot" #default>
			<slot />
		</template>
	</MiniCard>
	<MicroCard v-else-if="props.showMicro" :text="props.zone.name" :image-src="imageUrl" :image-preview="imagePreview" :router-link="routerLink">
		<template v-if="hasDefaultSlot" #default>
			<slot />
		</template>
	</MicroCard>
	<Card v-else :top-text="props.zone.name" :top-sub-text="props.zone.area().name" :bottom-text="venueCount" :image-src="imageUrl" :image-preview="imagePreview" :router-link="routerLink">
		<template v-if="hasDefaultSlot" #default>
			<slot />
		</template>
	</Card>
</template>

<script setup>
import { computed, useSlots } from "vue";

import { urlBuilder, useRouteParams } from "../helpers/index.js";
import { PageNames } from "../constants/index.js";
import ZONE_IMAGES from "../zone-images-hardcoded.json";

import Card from "./Card.vue";
import MiniCard from "./MiniCard.vue";
import MicroCard from "./MicroCard.vue";

const props = defineProps({
	zone: {
		type: Object,
		required: true,
		validator: (zone) => {
			return zone.id && zone.name && zone.area().name && zone.area().image && zone.venueCount;
		},
	},
	showMini: {
		type: Boolean,
		default: false,
	},
	showMicro: {
		type: Boolean,
		default: false,
	},
	showVenueCount: {
		type: Boolean,
		default: true,
	},
	imageSize: {
		type: Number,
		default: undefined,
	},
});

const slots = useSlots();

const hasDefaultSlot = computed(() => slots.default && slots.default().length > 0);
const imageUrl = computed(() => {
	const zoneImageUrl = ZONE_IMAGES.find(({ zoneId }) => zoneId === props.zone.id)?.imageURL;
	return zoneImageUrl && zoneImageUrl !== "" ? zoneImageUrl : props.zone.area().image.url;
});

const imagePreview = computed(() => {
	// const preview = props.zone.area().image.preview ?? null;
	// return preview;
	return null;
});

const venueCount = computed(() => {
	const numberOfVenues = props.zone.venueCount;
	return props.showVenueCount ? `${numberOfVenues} places` : null;
});

const routeParams = useRouteParams();

const routerLink = computed(() => {
	return urlBuilder(PageNames.EXPLORE_ZONE, { cityName: routeParams.cityName, zone: props.zone });
});
</script>
