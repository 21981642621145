<template>
	<Dialog
		ref="dialogRef"
		param-name="venueProductEnquiry"
		class="venue-product-enquiry-dialog"
		width="90vw"
		height="min(80dvh, 850px)"
		max-width="min(95vw, 420px)"
		:is-fake-modal="true"
		@closed="closed"
	>
		<DialogSimpleLayout :full-width="true" :show-padding="false" :header="venueProduct?.name" @close="close">
			<loading-message-with-error v-if="queries.isLoading || queries.error" class="top-loading-message" :error="queries.error" :retry="queries.retry" :full-screen="true">
				Loading place, please wait...
			</loading-message-with-error>
			<error-message v-else-if="!venue" :full-screen="true">No place found</error-message>
			<error-message v-else-if="!venueProduct" :full-screen="true">No event found</error-message>
			<VenueProductEnquiry v-else :venue="venue" :venue-product="venueProduct" />
		</DialogSimpleLayout>
	</Dialog>
	<div ref="measureScrollBarWidthRef" class="measure-scrollbar-width"></div>
</template>

<script setup>
import { computed, inject, ref } from "vue";

import { useQueryString } from "../../functions/query-string/index.js";
import { combineQueries } from "../../helpers/index.js";
import { useUser } from "../../composables/index.js";

import Dialog from "../Dialog.vue";
import DialogSimpleLayout from "../DialogSimpleLayout.vue";
import VenueProductEnquiry from "./VenueProductEnquiry.vue";

const queryString = useQueryString({
	venueId: null,
	venueProductId: null,
});
const { isSignedIn } = useUser();

const model = inject("model");

const dialogRef = ref(null);
const measureScrollBarWidthRef = ref(null);

const queries = combineQueries({
	venueDetail: model.queries.GetVenueDetail({ venueId: computed(() => queryString.venueId), isSignedIn }),
});

const venue = computed(() => queries.model?.venue);
const venueProduct = computed(() => venue.value?.products?.find((product) => product.id === queryString.venueProductId));

defineExpose({
	open,
	close,
});

async function open({ venueId, venueProductId } = {}) {
	const dialog = dialogRef.value;
	if (!venueId) {
		throw new Error("Venue ID is required to open the venue product enquiry dialog.");
	}
	if (!venueProductId) {
		throw new Error("Venue product ID is required to open the venue product enquiry dialog.");
	}

	queryString.venueId = venueId;
	queryString.venueProductId = venueProductId;

	if (dialog && !dialog.isOpen) {
		return dialog.open();
	}
}

async function close() {
	const dialog = dialogRef.value;
	dialog.close();
}

function closed() {
	queryString.remove("venueId");
	queryString.remove("venueProductId");
}
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

.venue-product-enquiry-dialog {
	overflow: hidden;
}
</style>
