<template>
	<div class="admin-data-service-performance-summary">
		<div class="environment-details">
			<dt class="header">Current Environment</dt>
			<dd class="cell">{{ currentEnvironmentName }}</dd>
			<dt class="header">API Version</dt>
			<dd class="cell">{{ currentEnvironmentApiVersion }}</dd>
		</div>
		<div class="controls">
			<button class="push-button-tertiary" @click="copyToClipboard">Copy to clipboard</button>
		</div>
		<div class="grid-container">
			<div class="header">Operation</div>
			<div class="header">Arguments</div>
			<div class="header">Timings</div>
			<template v-for="operation in operations" :key="operation.name">
				<div class="cell name">{{ operation.name }}</div>
				<div class="cell arg-key">{{ operation.argKey }}</div>
				<div class="cell timings-average">{{ Math.round(operation.timingsAverage) }}ms</div>
			</template>
		</div>
	</div>
</template>

<script setup>
import { inject, computed, unref } from "vue";
import dayjs from "dayjs";

import { useUser } from "../../composables/index.js";

const environmentManager = inject("environmentManager");
const currentEnvironment = inject("currentEnvironment");
const config = inject("config");

const { activeUser } = useUser();

const operationPerformance = computed(() => currentEnvironment.value.dataService.operationPerformance);
const operations = computed(() =>
	Object.entries(operationPerformance.value)
		.map(([name, args]) => {
			return Object.entries(args).map(([argKey, timings]) => {
				return { clientVersion: config.clientVersion, name, argKey, timings, timingsAverage: timings.reduce((acc, { durationMS }) => acc + durationMS, 0) / timings.length };
			});
		})
		.flat()
		.sort((a, b) => a.name.localeCompare(b.name)),
);

const currentEnvironmentName = computed(() => environmentManager.currentEnvironment.value?.name);
const currentEnvironmentApiVersion = computed(() => unref(environmentManager.currentEnvironment.value?.apiVersion));

async function copyToClipboard() {
	const clientIP = await getIPFromAmazon();
	const rows = operations.value.map(({ clientVersion, name, argKey, timingsAverage }) => [
		dayjs().format("YYYY-MM-DD HH:mm:ss"),
		currentEnvironmentName.value,
		currentEnvironmentApiVersion.value,
		clientVersion,
		clientIP,
		activeUser.value?.email,
		name,
		argKey,
		Math.round(timingsAverage),
	]);
	const tsvOutput = rows.map((row) => row.join("\t")).join("\n");
	navigator.clipboard.writeText(tsvOutput);
}

async function getIPFromAmazon() {
	const dataRaw = await fetch("https://checkip.amazonaws.com/");
	const clientIP = (await dataRaw.text()).trim();
	return clientIP;
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables_new.scss";

.admin-data-service-performance-summary {
	display: flex;
	flex-direction: column;
	max-width: 100%;

	> * {
		padding-bottom: calc($spacing * 2);

		&:not(:first-child) {
			padding-top: calc($spacing * 2);
			border-top: $border-size-primary solid $border-color-quinary;
		}
	}

	.header {
		font-weight: bold;
		padding: calc($spacing / 2);
		min-width: 0;
		white-space: nowrap;
		background-color: $background-color-quaternary;
		border-radius: $border-radius-primary;
		color: $background-color-primary;
	}

	.cell {
		border: $border-size-primary solid $border-color-quinary;
		padding: calc($spacing / 2);
	}

	.environment-details {
		display: grid;
		grid-template-columns: auto 1fr;
		gap: calc($spacing / 2);

		dt,
		dd {
			margin: 0;
		}

		.cell {
			color: $text-color-onezone-2;
		}
	}

	.grid-container {
		display: grid;
		grid-template-columns: auto 1fr min-content;
		gap: calc($spacing / 2);
		width: 100%;

		.cell {
			min-width: 0;

			&.name {
				white-space: nowrap;
				color: $text-color-onezone-2;
			}

			&.arg-key {
				overflow: auto;
				color: $text-color-secondary;
			}

			&.timings-average {
				white-space: nowrap;
				color: $text-color-onezone-1;
			}
		}
	}
}
</style>
