<template>
	<VenueDetailSection title="Tags" class="venue-tags">
		<router-link v-for="tag in sortedTagsToShow" :key="tag" :to="{ name: PageNames.EXPLORE_TAG, params: { tagId: tag.id } }" class="tag">
			<SearchContextLabel :has-remove-button="false" :invert-colors="false">{{ tag.name }}</SearchContextLabel>
		</router-link>
		<!-- <div v-if="hasMoreTags" class="show-all-tags">
			<button class="link-button-primary" @click="toggleShowAllTags">{{ toggleShowAllTagsButtonText }}</button>
		</div> -->
	</VenueDetailSection>
</template>

<script setup>
import { computed, ref } from "vue";

import { TagCategory, PageNames, SortOrder } from "../../constants/index.js";
import { sortZones } from "../../helpers/sorters/index.js";

import VenueDetailSection from "./VenueDetailSection.vue";
import SearchContextLabel from "../SearchContextLabel.vue";

const SHOW_MORE_LIMIT = 10;

const props = defineProps({
	venue: {
		type: Object,
		required: true,
	},
});

// const shouldShowAllTags = ref(false);
const shouldShowAllTags = ref(true);

const tagsToShow = computed(() => props.venue.tags?.filter(({ category }) => category !== TagCategory.MEAL_TYPE) ?? []);
const sortedTagsToShow = computed(() => [...tagsToShow.value].sort(sortZones[SortOrder.NAME]).slice(0, shouldShowAllTags.value ? Number.MAX_VALUE : SHOW_MORE_LIMIT));
// const hasMoreTags = computed(() => tagsToShow.value.length > SHOW_MORE_LIMIT);
// const toggleShowAllTagsButtonText = computed(() => (shouldShowAllTags.value ? "Show less" : "Show more"));

// function toggleShowAllTags() {
// 	shouldShowAllTags.value = !shouldShowAllTags.value;
// }
</script>

<style scoped lang="scss">
@import "../../assets/styles/variables_new.scss";

// .venue-tags {
// 	.show-all-tags {
// 		width: 100%;
// 	}
// }
</style>
